import { get, isEmpty } from "lodash";
import c from "classnames";
import React, { useEffect, useState } from "react";
import InviteButtons from "../components/InviteButtons/InviteButtons";
import LanguageToggle from "../components/LanguageToggle/LanguageToggle";
import { replacePlaceholders } from "../utils/utils";

import styles from "./Survey.module.scss";
import { recordAnswer, recordView } from "../api/inviteApi";
import {
  Question,
  QUESTION_TYPE,
  QUESTION_TYPE_ANSWER_KEY,
} from "./Survey/Questions";

const DEFAULT_DISPLAY_MESSAGE = <>Take a minute to give us feedback</>;
const DEFAULT_THANKS_MESSAGE = <>Thanks for completing our survey</>;

export const Button = ({
  className,
  children,
  primary,
  secondary,
  small = true,
  ...props
}) => {
  return (
    <button
      {...props}
      className={c("button", className, {
        button__secondary: secondary,
        button__primary: primary,
        "button__size-small": small,
      })}
    >
      <span>{children}</span>
    </button>
  );
};

const Footer = () => (
  <footer className="trademark-footer">
    {"Powered by"}
    <br />
    <img alt="Widewail" src="img/Widewail_Logo.svg" width="110px" />
    <br />
    &copy;{new Date().getFullYear()} Google LLC All rights reserved. Google and
    the Google logo are registered trademarks of Google LLC.
  </footer>
);

const Logo = ({ customer = {} }) => {
  const { companyLogo, companyName } = customer;

  return companyLogo ? (
    <img className="companylogo" src={companyLogo} alt={companyName} />
  ) : null;
};

const SurveyStart = ({ data, onStart }) => {
  const {
    landingPageText,
    customer,
    survey: { questions = [] },
  } = data;
  const surveyStartMessage = landingPageText
    ? replacePlaceholders(landingPageText, data)
    : DEFAULT_DISPLAY_MESSAGE;

  return (
    <div className={styles.welcome}>
      <Logo customer={customer} />
      <br />
      {surveyStartMessage}
      <br />
      <br />
      <small className={styles.surveyProgress} data-cy-element="survey-length">
        {questions?.length} Questions
      </small>
      <br />
      <Button primary onClick={onStart} data-cy-element="getStarted">
        Get Started
      </Button>
    </div>
  );
};

const SurveyEnd = ({ data, inviteShortCode, t }) => {
  const { thankYouPageText, customer } = data;
  const surveyEndMessage = thankYouPageText
    ? replacePlaceholders(thankYouPageText, data)
    : DEFAULT_THANKS_MESSAGE;

  return (
    <div className={styles.welcome}>
      <Logo customer={customer} />
      <br />
      <small
        data-cy-element="survey-progress"
        className={styles.surveyProgress}
      >
        Survey Complete
      </small>
      {surveyEndMessage}
      <br />
      <InviteButtons data={data} code={inviteShortCode} t={t} />
    </div>
  );
};

export const getFirstUnansweredQuestionIndex = (questions) =>
  questions.findIndex((q) => {
    return isEmpty(q?.skipped) && isEmpty(getAnswer(q)?.toString());
  }),
  wasQuestionSkipped = (q) => q?.skipped || isEmpty(getAnswer(q)?.toString()),
  submitAnswer = (inviteShortCode, q = {}, order) => {
    if (isEmpty(inviteShortCode) || isEmpty(q)) return null;

    const { id, type } = q;
    const answerKey = QUESTION_TYPE_ANSWER_KEY[type];
    const answer = getAnswer(q);
    const skipped = wasQuestionSkipped(q);

    const postBody = {
      questionId: id,
      skipped,
      order,
    };

    if (!skipped) postBody[answerKey] = answer;

    return recordAnswer(inviteShortCode, postBody);
  },
  getAnswer = (question = {}) =>
    question[QUESTION_TYPE_ANSWER_KEY[question?.type]];

export default function Survey({ data, changeLanguage, code, t }) {
  const { survey } = data;

  const [questions, setQuestions] = useState(survey?.questions || []);
  const [surveyStarted, setSurveyStarted] = useState(
    getFirstUnansweredQuestionIndex(questions) > 0 // if the first question is unanswered, assume unstarted
  );

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState();
  const currentQuestion = get(questions, currentQuestionIndex, null);

  const surveyComplete =
    currentQuestionIndex >= questions?.length || // if the currentQuestionIndex went past the end or
    currentQuestionIndex < 0 || // if the reloaded survey state has no remaining unsanswered questions or
    isEmpty(currentQuestion); // there's no question to show

  const setAnswer = (answer, autoSubmit = true) => {
    const newQuestions = [...questions],
      question = newQuestions[currentQuestionIndex],
      questionWithAnswer = {
        ...question,
        skipped: isEmpty(answer?.toString()),
        [QUESTION_TYPE_ANSWER_KEY[question?.type]]: answer,
      };

    autoSubmit && submitAnswer(code, questionWithAnswer, currentQuestionIndex);

    newQuestions[currentQuestionIndex] = questionWithAnswer;
    setQuestions(newQuestions);
  };

  const goToNextQuestion = (submitOnMove = true) => {
    (submitOnMove || wasQuestionSkipped(questions[currentQuestionIndex])) &&
      submitAnswer(
        code,
        questions[currentQuestionIndex],
        currentQuestionIndex
      );

    setCurrentQuestionIndex(
      Math.min(questions.length, currentQuestionIndex + 1)
    );
  },
    goToPreviousQuestion = (submitOnMove = true) => {
      const newIdx = currentQuestionIndex - 1;
      if (newIdx < 0) setSurveyStarted(false);

      submitOnMove &&
        submitAnswer(
          code,
          questions[currentQuestionIndex],
          currentQuestionIndex
        );

      setCurrentQuestionIndex(Math.max(0, newIdx));
    };
  const questionId = questions[currentQuestionIndex]?.id

  // if returning to the survey, move the survey to the next unanswered question on initial load
  useEffect(() => {
    setCurrentQuestionIndex(getFirstUnansweredQuestionIndex(questions));
  }, []);

  // when a question is viewed, send a message saying so
  useEffect(() => {
    surveyStarted &&
      questionId &&
      recordView(code, {
        questionId: questions[currentQuestionIndex]?.id,
        order: currentQuestionIndex,
      });
  }, [surveyStarted, currentQuestionIndex, questionId, recordView, code]);

  return (
    <div className={styles.survey}>
      <LanguageToggle changeLanguage={changeLanguage} />
      <main className={c("main__invite-landing", styles.surveyWrapper)}>
        {!surveyStarted && !surveyComplete && (
          <SurveyStart data={data} onStart={() => setSurveyStarted(true)} />
        )}
        {surveyComplete && <SurveyEnd data={data} t={t} />}
        {surveyStarted && !surveyComplete && (
          <>
            <small
              className={styles.surveyProgress}
              data-cy-element="survey-progress"
            >
              Question #{currentQuestionIndex + 1} of {questions?.length}
            </small>
            <SurveyQuestion
              question={currentQuestion}
              setAnswer={setAnswer}
              goToNextQuestion={goToNextQuestion}
              goToPreviousQuestion={surveyStarted ? goToPreviousQuestion : null}
            />
          </>
        )}
      </main>
      <Footer />
    </div>
  );
}

export const SurveyQuestion = ({
  question = {},
  setAnswer,
  goToPreviousQuestion,
  goToNextQuestion,
}) => {
  const submitOnMove = question?.type === QUESTION_TYPE.freeText;

  return (
    <div className={styles.surveyQuestion}>
      <div className={styles.surveyQuestionBody}>
        <Question
          question={question}
          setAnswer={setAnswer}
          submitOnMove={submitOnMove}
        />
      </div>
      <div className={styles.surveyQuestionControls}>
        {typeof goToPreviousQuestion === "function" && (
          <Button
            small
            data-cy-element="previousQuestion"
            style={{ minWidth: "unset" }}
            onClick={() => goToPreviousQuestion(submitOnMove)}
          >
            <i className="fa fa-chevron-left" />
            Previous
          </Button>
        )}
        {typeof goToNextQuestion === "function" && (
          <Button
            small
            data-cy-element="nextQuestion"
            style={{ minWidth: "unset" }}
            onClick={() => goToNextQuestion(submitOnMove)}
          >
            Next
            <i className="fa fa-chevron-right" />
          </Button>
        )}
      </div>
    </div>
  );
};
